import React, { useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Button, Form, Space, Input, notification } from 'antd';
import axios from 'axios';
import Captcha from '@front_office/components/Captcha';

import translator from '@webpack/translator';
const t = translator('front_office.views.registration.sign_up.change_phone.');

const ChangePhone = ({
  customer,
  setSection,
  setCustomerData,
  onlyRussianNumbersAllowed,
  defaultCountry,
}) => {
  const [form] = Form.useForm();
  const captchaRef = useRef(null);
  const skipCaptcha = new URLSearchParams(window.location.search).get('skip_captcha');
  const [disableSubmit, setDisableSubmit] = useState(skipCaptcha != 'true');

  const changeCountry = ({ countryCode }) => {
    form.setFieldsValue({ customer: { phone_iso: countryCode.toUpperCase() } });
  };

  const onChangeCaptcha = (value) => {
    setDisableSubmit(false);
  };

  const onFinish = (values) => {
    const token = captchaRef.current.getValue();

    let params = Object.assign(values, { 'g-recaptcha-response': token });
    if (skipCaptcha) params = Object.assign(params, { skip_captcha: skipCaptcha });

    axios({
      method: 'patch',
      url: `/front_office/change_customer_phone/${customer.id}`,
      data: params,
      headers: {
        Accept: 'application/json',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
    }).then(({ data }) => {
      if (data.success) {
        setCustomerData(data.customer);
        setSection('sms');
      } else {
        captchaRef.current.reset();

        data.errors.forEach((error) =>
          notification['error']({ description: error, placement: 'top' }),
        );
      }
    });
  };

  return (
    <>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.List name="customer">
          {(_fields) => (
            <>
              <Form.Item hidden name="phone_iso" initialValue={customer.id}>
                <Input />
              </Form.Item>

              <Form.Item label={t('phone_label')} name="phone">
                <PhoneInput
                  country={defaultCountry}
                  preferredCountries={
                    onlyRussianNumbersAllowed
                      ? [defaultCountry]
                      : [defaultCountry, 'kz', 'ua', 'by']
                  }
                  onlyCountries={onlyRussianNumbersAllowed ? [defaultCountry] : []}
                  inputClass="ant-input ant-input-lg"
                  containerClass="react-tel-input-custom-antd"
                  onChange={(_phone, countryData) => changeCountry(countryData)}
                />
              </Form.Item>
              <Form.Item>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Captcha ref={captchaRef} onChange={onChangeCaptcha} />

                  <Button block={true} type="primary" htmlType="submit" disabled={disableSubmit}>
                    {t('submit_button')}
                  </Button>
                  <Button
                    id="update_sms"
                    block={true}
                    type="text"
                    onClick={() => setSection('sms')}
                  >
                    {t('cancel_button')}
                  </Button>
                </Space>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </>
  );
};

export default ChangePhone;
