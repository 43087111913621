import React, { useEffect, useState } from 'react';
import SelectorView from '@front_office/components/SelectorView';
import { Row, Col, Typography, DatePicker, Select, TimePicker, Radio } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useClientsState } from '../State';
import translator from '@webpack/translator';
import moment from 'moment';
import Helper from '@webpack/helper.js';

import v1_select_arrow from '@imgs/front_office/form/v1_select_arrow.svg';

const t = translator('front_office.views.yclients_customer_clients.form.dispatch_setting_step.');
const { Text } = Typography;

const createDispatchSettingStep = ({ isForBot = false } = {}) => ({
  title: t('title'),
  validation: ({ dispatch_routing }) =>
    (dispatch_routing || []).some(([_channel, isActive]) => !!isActive),
  Component: ({ isVisible }) => {
    const disabled = isForBot;
    const { state } = useClientsState();
    const dispatchDefaultValue = isForBot ? state.bot_channels : state.channels;
    const helpText = isForBot
      ? t('fields.dispatch_routing_help_bot_feedback_request')
      : t('fields.dispatch_routing_help');

    const { setValue, register } = useFormContext();
    const [radioValue, setRadioValue] = useState('send_now');
    const [routingValue, setRoutingValue] = useState(dispatchDefaultValue);
    const [selectedDate, setSelectedDateValue] = useState(moment().format('DD-MM-YYYY'));
    const [selectedTime, setSelectedTimeValue] = useState(moment().format('HH:mm'));
    const [selectedTimeZone, setSelectedTimeZoneValue] = useState(
      Helper.formatUTCOffset(Helper.getUTCOffset(state.defaultTimezone || '+03:00')),
    );
    const navigate = useNavigate();

    const disabledDate = (current) => {
      return moment().add(-1, 'days') >= current || moment().add(1, 'month') <= current;
    };

    const onChangeRadioValue = (value) => {
      setRadioValue(value);
      if (value == 'schedule') {
        setValue('scheduled_at', `${selectedDate} ${selectedTime} ${selectedTimeZone}`);
      } else {
        setValue('scheduled_at', ``);
      }
    };

    useEffect(() => {
      setValue('scheduled_at', `${selectedDate} ${selectedTime} ${selectedTimeZone}`);
    }, [selectedDate, selectedTime, selectedTimeZone]);

    useEffect(() => {
      if (radioValue == 'send_now') {
        setValue('scheduled_at', '');
      }
    }, [radioValue]);

    const formatDispatchChannels = (channels) => {
      setRoutingValue(channels);
      setValue('dispatch_routing', formatRouting(channels));
    };

    const formatRouting = (channels) => {
      return channels.filter(([, isActive]) => isActive).map(([channel]) => channel);
    };

    return (
      <Row gutter={[0, 24]} style={{ display: isVisible ? 'flex' : 'none' }}>
        <Col sm={24} xl={6} />
        <Col sm={24} xl={12}>
          <Row gutter={[0, 32]}>
            <Col span={24}>
              <Text strong>{t('fields.receivers')}</Text>
            </Col>
            <Col span={24}>
              <Text>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('fields.receivers_context', {
                      count: state.selectedPhoneNumbers.length,
                    }),
                  }}
                />
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault(), navigate('/');
                  }}
                  className="underline_link"
                >
                  {t('fields.receivers_context_back_button')}
                </a>
              </Text>
            </Col>
            <Col span={24}>
              <Text strong>{t('fields.dispatch_time')}</Text>
            </Col>
            <Col span={24}>
              <Radio.Group onChange={(e) => onChangeRadioValue(e.target.value)} value={radioValue}>
                <Radio value={'send_now'}>{t('fields.send_now')}</Radio>
                <Radio value={'schedule'}>{t('fields.schedulet_send')}</Radio>
              </Radio.Group>
              {radioValue == 'schedule' && (
                <div className="sheduled_box">
                  <input type="hidden" {...register('scheduled_at')} />

                  <Col span={24}>
                    <DatePicker
                      id="scheduled_date"
                      style={{ width: '100%' }}
                      size="large"
                      minWidth="100%"
                      format={'DD-MM-YYYY'}
                      placeholder={t('select_date')}
                      defaultValue={moment()}
                      showToday={false}
                      onChange={(_, dateString) => {
                        setSelectedDateValue(dateString);
                      }}
                      disabledDate={disabledDate}
                    />
                  </Col>
                  <Col span={24}>
                    <TimePicker
                      format={'HH:mm'}
                      style={{ width: '100%' }}
                      placeholder=""
                      size="large"
                      className="ant-custom-input"
                      popupClassName="ant-custom-picker-dropdown"
                      clearIcon={false}
                      showNow={false}
                      defaultValue={moment()}
                      onChange={(_, timeString) => {
                        setSelectedTimeValue(timeString);
                      }}
                    />
                  </Col>
                  <Col span={24}>
                    <Select
                      size="large"
                      showSearch
                      style={{ width: '100%' }}
                      filterOption={(inputValue, option) =>
                        (option?.label?.toLowerCase() ?? '').includes(inputValue.toLowerCase())
                      }
                      popupClassName="bulk_deliveries-ant-select-custom-dropdown"
                      defaultValue={state.defaultTimezone}
                      suffixIcon={<img src={v1_select_arrow} />}
                      onChange={(value) => {
                        let [day, month, year] = moment().format('DD-MM-YYYY').split('-');
                        let offset = Helper.formatUTCOffset(
                          Helper.getUTCOffset(value, new Date(year, month, day)),
                        );

                        setSelectedTimeZoneValue(offset);
                      }}
                    >
                      {state.timezones.map((timezone) => (
                        <Option key={timezone[1]} value={timezone[1]} label={timezone[0]}>
                          {timezone[0]}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </div>
              )}
            </Col>
            <Col span={24}>
              <Text strong>{t('fields.dispatch_routing')}</Text>
            </Col>
            <Col span={24}>
              <Text>{helpText}</Text>
            </Col>
            <Col sm={24} xl={24}>
              <input
                type="hidden"
                {...register('dispatch_routing', { value: formatRouting(routingValue) })}
              />
              <SelectorView
                disabled={disabled}
                channels={routingValue}
                setChannelsOrder={formatDispatchChannels}
                hasTelegramBot={state.has_telegram_bot}
                hasTdLib={state.has_td_lib}
                editTelegramBotsUrl={state.edit_telegram_bots_url}
                editTdLibSessionsUrl={state.edit_td_lib_sessions_url}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={0} xl={6} />
      </Row>
    );
  },
});

export default createDispatchSettingStep;
