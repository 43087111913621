import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Col,
  Row,
  Table,
  Space,
  Grid,
  Select,
  Checkbox,
  Pagination,
  Button,
  Input,
  Breadcrumb,
} from 'antd';
import { useClientsState } from './State.js';

import translator from '@webpack/translator';
const t = translator('front_office.views.yclients_customer_clients.booking_table.');

const ClientsTable = ({}) => {
  const { state, updateState } = useClientsState();
  let [searchParams, _] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [allCheckboxMarked, setAllCheckboxMarked] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [checkedClients, setChekedClients] = useState([]);
  const [currentPerPage, setCurrentPerPage] = useState(
    searchParams.get('per_page') || state.per_pages[0],
  );
  const [currentPage, setCurrentPage] = useState(searchParams.get('page') || 1);

  const [filterParams, setFilterParams] = useState({});

  const screens = Grid.useBreakpoint();
  const navigate = useNavigate();

  const onLinkClick = (_e) => {
    updateState({ selectedPhoneNumbers: checkedClients });
    navigate(`/new`);
  };

  const fetchTableData = () => {
    setIsLoading(true);
    const params = {
      page: currentPage,
      per_page: currentPerPage,
      q: filterParams,
    };
    axios('/front_office/yclients_customer_clients', {
      params: params,
      headers: { Accept: 'application/json' },
    })
      .then(({ data }) => {
        if (data.success) {
          updateState({ clients: data.clients, clients_count: data.clients_count });
        } else {
          updateState({ clients: [], clients_count: 0 });
        }
      })
      .catch((_error) => {
        updateState({ clients: [], clients_count: 0 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  useEffect(() => {
    setDisabledButton(checkedClients.length == 0);
  }, [checkedClients]);

  useEffect(() => {
    setDataToDisplay(state.clients);
  }, [state.clients]);

  useEffect(() => {
    fetchTableData();
    navigate(`?page=${currentPage}&per_page=${currentPerPage}`, { replace: true });
  }, [currentPage, currentPerPage, filterParams]);

  const onClickMainCheckbox = (event) => {
    if (event.target.checked) {
      setAllCheckboxMarked(true);
      let phone_numbers = state.clients.map((client) => {
        return client['phone_number'];
      });

      setChekedClients(phone_numbers);
    } else {
      setAllCheckboxMarked(false);
      setChekedClients([]);
    }
  };

  const onSearch = (value) => {
    setFilterParams({ name_or_phone_cont: value });
  };

  const onClickSoloCheckbox = (event, record) => {
    let data = [...checkedClients];
    if (event.target.checked) {
      data.unshift(record.phone_number);
      setChekedClients(data);
    } else {
      setChekedClients(
        data.toString() != [].toString() &&
          data.filter((elem) => {
            return elem !== record['phone_number'];
          }),
      );
    }
  };

  const columns = [
    {
      title: () => {
        return <Checkbox checked={allCheckboxMarked} onChange={(e) => onClickMainCheckbox(e)} />;
      },
      dataIndex: 'main_checkbox',
      width: '10%',
      render: (_, record) => {
        return (
          <Checkbox
            onChange={(e) => onClickSoloCheckbox(e, record)}
            checked={checkedClients.includes(record.phone_number)}
          />
        );
      },
    },
    {
      responsive: ['md'],
      title: t('table.fields.full_name'),
      dataIndex: 'full_name',
      width: '45%',
    },
    {
      responsive: ['md'],
      title: t('table.fields.phone_number'),
      dataIndex: 'phone_number',
      width: '45%',
    },
  ];

  const expandedRowRender = ({ items }) => {
    return (
      <Table
        className="ant-custom-table"
        rowKey="id"
        onRow={() => ({ style: { backgroundColor: '#FAFAFA' } })}
        onCell={() => ({ style: { backgroundColor: '#FAFAFA' } })}
        onHeaderRow={() => ({ style: { visibility: 'collapse' } })}
        showHeader={true}
        columns={columns}
        dataSource={items}
        pagination={false}
      />
    );
  };

  return (
    <div className="yclients_customer_clients">
      <Breadcrumb className="ant-custom-breadcrumbs">
        <Breadcrumb.Item>{t('breadcrumb.clients')}</Breadcrumb.Item>
      </Breadcrumb>
      <Space direction="vertical" size={24} style={{ width: '100%' }}>
        <Row>
          <Col span={18}></Col>
          <Col span={6}>
            <Input.Search
              placeholder={t('search_input')}
              onSearch={(value, _e, _s) => onSearch(value)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Space direction="vertical" size={24} style={{ width: '100%' }}>
              <Row>
                <Col span={6}></Col>
                <Col span={12}></Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Table
                    loading={isLoading}
                    className="ant-custom-table"
                    scroll={{ x: true }}
                    expandable={{
                      expandedRowRender,
                      defaultExpandAllRows: true,
                      expandRowByClick: true,
                      rowExpandable: ({ items }) => (items || []).length > 0,
                    }}
                    rowKey="action_type"
                    columns={columns}
                    dataSource={dataToDisplay}
                    showHeader={screens.md}
                    pagination={false}
                  />
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <div>
              {t('pagination.how_show', { total: state.clients_count, count: currentPerPage })}
            </div>
          </Col>
          <Col span={8}>
            <Pagination
              className="ant-custom-pagination"
              defaultCurrent={currentPage}
              total={state.clients_count}
              showSizeChanger={false}
              onChange={(page, _) => setCurrentPage(page)}
              pageSize={currentPerPage}
            />
          </Col>
          <Col span={8}>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <Select
                className="ant-custom-select"
                defaultValue={t('select_perp_page', { value: currentPerPage })}
                style={{ width: '50%', maxWidth: 420 }}
                options={state.per_pages.map((value) => {
                  return { value: value, label: t('select_perp_page', { value: value }) };
                })}
                popupClassName="ant-custom-select-dropdown"
                onChange={(value) => {
                  setCurrentPerPage(value);
                }}
              />
            </div>
          </Col>
        </Row>
      </Space>
      {!disabledButton && (
        <div className="flow_bar">
          <div className="flow_bar-buttons">
            <Space size={8}>
              <Button type="text" variant="solid">
                {t('flow_bar.clients_count', { count: checkedClients.length })}
              </Button>
              <Button type="primary" disabled={disabledButton} onClick={onLinkClick}>
                {t('flow_bar.send_button')}
              </Button>
            </Space>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientsTable;
