import React, { useRef, useState, useEffect } from 'react';
import EmojiPicker from '@front_office/components/EmojiPicker';
import { SmileOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Input, Row, Col, Typography, Dropdown, Popover, Button, Checkbox, Tooltip } from 'antd';
import WarningAlert from './WarningAlert';
import AttachmentUpload from './AttachmentUpload';
import { useFormContext, Controller } from 'react-hook-form';
import { useClientsState } from '../State';
import translator from '@webpack/translator';
import classNames from 'classnames';
import TextPreview from './TextPreview';
import SmsProgressBar from './SmsProgressBar';

const t = translator('front_office.views.yclients_customer_clients.form.create_text_step.');
const { Text } = Typography;
const { TextArea } = Input;

const createTextStep = ({
  alerts = [],
  disabled = false,
  helpText = t('fields.text_help'),
} = {}) => ({
  title: t('title'),
  validation: ({ text, attachment_id }, { text_step_errors }) => {
    let errors = (text_step_errors || []).length === 0;
    const validations = () => {
      if (attachment_id) {
        return true;
      }

      if (text && (text || '').length > 0) {
        return true;
      }
    };
    return errors && validations();
  },
  Component: ({ isVisible }) => {
    const { state } = useClientsState();
    const { control, setValue, watch } = useFormContext();
    const currentText = watch('text');
    const dispatchRouting = watch('dispatch_routing') || [];
    const shortUrl = watch('short_url');
    const [lastInsertPosition, setLastInsertPosition] = useState(-1);
    const [needUrlShort, setNeedUrlShort] = useState(state.url_shorting);
    const textInputRef = useRef(null);
    const nativeTextArea = textInputRef.current?.resizableTextArea.textArea;

    const insertText = (text) => {
      const inputValue = nativeTextArea.value;
      const start = nativeTextArea.selectionStart;
      const end = nativeTextArea.selectionEnd === start ? start : nativeTextArea.selectionEnd;

      const before = inputValue.substring(0, start);
      const after = inputValue.substring(end);

      const newInputValueBeginning = before + text;
      const newInputValue = newInputValueBeginning + after;

      setValue('text', newInputValue);

      setLastInsertPosition(newInputValueBeginning.length);
    };

    useEffect(() => {
      if (lastInsertPosition >= 0) {
        nativeTextArea.setSelectionRange(lastInsertPosition, lastInsertPosition);
        setLastInsertPosition(-1);
      }
    }, [lastInsertPosition]);

    const bodyVariableOptions = {
      items: state.body_variables,
      onClick: ({ key }) => insertText(key),
    };

    const isErrorPresent = (state.text_step_errors || []).length !== 0;

    return (
      <Row gutter={[0, 24]} style={{ display: isVisible ? 'flex' : 'none' }}>
        {alerts.map((alert, index) => (
          <WarningAlert key={index} message={alert} />
        ))}
        {disabled && (
          <WarningAlert
            message={
              <span>
                {t('alerts.disabled_text_start')}
                <a style={{ textDecoration: 'underline' }} target="_blank" href="/whatsappproblems">
                  {t('alerts.disabled_text_link_text')}
                </a>
                {t('alerts.disabled_text_end')}
              </span>
            }
          />
        )}
        <Col span={1}></Col>
        <Col span={11}>
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Text strong>{t('fields.text')}</Text>
            </Col>
            <Col span={24}>
              <Text>{t('fields.text_help')}</Text>
            </Col>
            <Col span={24}>
              <div
                className={classNames('yclients_customer_clients-create__text-input', {
                  'yclients_customer_clients-create__text-input_error': isErrorPresent,
                })}
              >
                <Controller
                  control={control}
                  name="text"
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <TextArea
                      disabled={disabled}
                      autoSize
                      bordered={false}
                      ref={textInputRef}
                      value={value}
                      onChange={onChange}
                      style={{ padding: 0, flexGrow: 1 }}
                    />
                  )}
                />
                {!disabled && (
                  <div className="yclients_customer_clients-create__text-input-actions">
                    {/* <Dropdown
                      trigger="click"
                      menu={bodyVariableOptions}
                      overlayClassName="ant-custom-dropdown"
                    >
                      <Button className="ant-custom-btn">
                        {t('fields.text_variables')}
                        <PlusOutlined />
                      </Button>
                    </Dropdown> */}
                    <Popover
                      placement="right"
                      popupClassName={'yclients_customer_clients-create'}
                      trigger="click"
                      content={
                        <EmojiPicker
                          stylesStr="section.picker{border: none;}"
                          onClick={(e) => insertText(e.detail.unicode)}
                        />
                      }
                    >
                      <Button className="ant-custom-btn" htmlType="button">
                        <SmileOutlined />
                      </Button>
                    </Popover>
                  </div>
                )}
              </div>
              {isErrorPresent && (
                <div className="yclients_customer_clients-create__text-input-error-message">
                  {state.text_step_errors.join(', ')}
                </div>
              )}
            </Col>
            {dispatchRouting.includes('sms') && (
              <Col span={24}>
                <SmsProgressBar
                  isSmsSelected={dispatchRouting.includes('sms')}
                  currentText={currentText}
                  shortUrl={needUrlShort}
                />
              </Col>
            )}
            <Col span={24}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  defaultValue={needUrlShort}
                  checked={needUrlShort}
                  onChange={(e) => {
                    setNeedUrlShort(e.target.checked), setValue('url_shorting', e.target.checked);
                  }}
                >
                  {t('fields.url_short_checkbox')}
                </Checkbox>
                <Tooltip title={t('fields.url_short_help', { example_url: 'https://test.com' })}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            </Col>
            <Col span={24}>
              <AttachmentUpload />
            </Col>
          </Row>
        </Col>
        <Col sm={0} xl={1} />
        <Col sm={24} xl={11}>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
            <Text strong>{t('fields.reciver_preview')}</Text>
          </div>

          <TextPreview
            sender_name={'Салон'}
            text={currentText}
            attachmentUrl={state.currentFileUrl}
            shortUrl={needUrlShort}
          />
        </Col>
      </Row>
    );
  },
});

export default createTextStep;
