import React from 'react';
import { Col, Row, Space, Button, Breadcrumb } from 'antd';
import { useClientsState } from './State.js';
import { useNavigate } from 'react-router-dom';

import bulk_success from '@imgs/front_office/bulk_deliveries/bulk_success.svg';

import translator from '@webpack/translator';
const t = translator('front_office.views.yclients_customer_clients.finish_step.');

const Success = ({ response }) => {
  return (
    <Space direction="vertical" size={24} style={{ width: '100%', alignItems: 'center' }}>
      <Row>
        <Col>
          <img src={bulk_success} />
        </Col>
      </Row>
      <Row>
        <Col>{response.title}</Col>
      </Row>
      <Row>
        <Col>{response.desc}</Col>
      </Row>
      <Row>
        <Col>
          <Button className="ant-custom-btn" type="primary" href={t('success.button_href')}>
            {t('success.button_text')}
          </Button>
        </Col>
      </Row>
    </Space>
  );
};

const Error = ({ errors }) => {
  const navigate = useNavigate();

  return (
    <Space direction="vertical" size={24} style={{ width: '100%', alignItems: 'center' }}>
      <Row>
        <Col>{t('error.title')}</Col>
      </Row>
      <Row>
        {errors && errors.map((error, i) => <Col key={i}>{error}</Col>)}
        {!errors && <Col>{t('error.desc')}</Col>}
      </Row>
      <Row>
        <Col>
          <Button className="ant-custom-btn" type="primary" onClick={() => navigate('/new')}>
            {t('error.button_text')}
          </Button>
        </Col>
      </Row>
    </Space>
  );
};

const FinishStep = ({}) => {
  const { state } = useClientsState();

  const mainFrame = () => {
    if (!state.response) {
      return <Error />;
    }

    if (!state.response.success) {
      return <Error errors={state.response.errors} />;
    }

    if (state.response.success) {
      return <Success response={state.response} />;
    }
  };

  return (
    <>
      <Breadcrumb className="ant-custom-breadcrumbs">
        <Breadcrumb.Item>{t('breadcrumb.clients')}</Breadcrumb.Item>
        <Breadcrumb.Item>{t('breadcrumb.send_form')}</Breadcrumb.Item>
      </Breadcrumb>
      {mainFrame()}
    </>
  );
};

export default FinishStep;
