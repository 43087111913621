import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import translator from '@webpack/translator';

const t = translator(
  'front_office.views.yclients_customer_clients.form.create_text_step.sms_progress_bar.',
);

const SmsProgressBar = ({ isSmsSelected, currentText, shortUrl }) => {
  const [smsLengthValue, setSmsLengthValue] = useState('0 / 160 ');
  const [smsCountValue, setSmsCountValue] = useState('0');

  const ruProgressBar = (len, smsTotal) => {
    $('#en_bar').css({ display: 'none' });
    $('#ru_bar').css({ display: 'inline' });
    $('#ru_bar_line').css({ width: `${calc_text_precent(len, smsTotal, -0.15, -0.1)}% ` });
  };

  const enProgressBar = (len, smsTotal) => {
    $('#ru_bar').css({ display: 'none' });
    $('#en_bar').css({ display: 'inline' });
    $('#en_bar_line').css({ width: `${calc_text_precent(len, smsTotal, 0.45, -0.9)}% ` });
  };

  const clearSmsTextarea = () => {
    ruProgressBar(0, 0);
    enProgressBar(0, 0);
  };

  function calc_text_precent(len, smsTotal, add_value, remove_value) {
    let maxLen = 938;
    if (smsTotal == 1) {
      return (len / maxLen) * 100 + add_value;
    } else if (smsTotal > 1 && smsTotal < 5) {
      return (len / maxLen) * 100 - remove_value;
    } else if (len > maxLen) {
      return 100;
    } else {
      return (len / maxLen) * 100;
    }
  }

  const shortLinksInText = (text) => {
    let urlRegex = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    if (shortUrl) {
      return text.replace(urlRegex, 'p-sms.ru/testme');
    } else {
      return text;
    }
  };

  const symbolsCount = (currentText) => {
    if (currentText === '') return 0;
    return shortLinksInText(currentText || '').length;
  };

  const countSms = (currentText) => {
    const nonLatinPattern = /[\u0400-\u04FF\{\}\[\]\\\|\^\~\€]/;
    let smsTotal = 0;
    let divider = 160;
    let len = symbolsCount(currentText);

    if (len === 0) {
      clearSmsTextarea();
      return;
    }

    if (nonLatinPattern.test(shortLinksInText(currentText))) {
      smsTotal = len > 70 ? Math.ceil(len / 67) : 1;
      divider = smsTotal > 1 ? 67 : 70;
      ruProgressBar(len, smsTotal);
    } else {
      smsTotal = len > 160 ? Math.ceil(len / 153) : 1;
      divider = smsTotal > 1 ? 153 : 160;
      enProgressBar(len, smsTotal);
    }

    setSmsLengthValue(`${len} / ${divider} `);
    setSmsCountValue(smsTotal);
  };

  useEffect(() => {
    countSms(currentText);
  }, [currentText]);

  const smsInfo = isSmsSelected && (
    <div className="sms_info">
      <div className="symbol_count">
        {t('symbol_count')} &nbsp;<b id="sms_length_value"> {smsLengthValue} </b>
      </div>
      <div className="sms_count">
        {t('sms_count')}&nbsp;<b id="sms_count_value"> {smsCountValue} </b>
      </div>
      <div className="sms_help">
        <Tooltip title={t('sms_info_help')}>
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    </div>
  );

  const isShowProgressBar = isSmsSelected;

  return (
    <div className="sms_progress_bar">
      {isShowProgressBar && (
        <Form.Item>
          <div className="sms_counter">
            <div className="progress_bar_box">
              <div id="ru_bar" className="progress_bar_box-ru">
                <div id="ru_bar_line" className="progress_bar_line">
                  {[...Array(13).keys()].map((num) => {
                    return (
                      <div key={`ru-divider-${num}`} className={`divider divider-${num}`}></div>
                    );
                  })}
                </div>
              </div>
              <div id="en_bar" className="progress_bar_box-en">
                <div id="en_bar_line" className="progress_bar_line">
                  {[...Array(5).keys()].map((num) => {
                    return (
                      <div key={`eng-divider-${num}`} className={`divider divider-${num}`}></div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {smsInfo}
        </Form.Item>
      )}
    </div>
  );
};

export default SmsProgressBar;
