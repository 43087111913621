import React from 'react';
import { locale } from '@webpack/translator';

const TextPreview = ({ sender_name, text, attachmentUrl, shortUrl }) => {
  const shortLinksInText = (text, shortUrl) => {
    let urlRegex = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    if (text && shortUrl) {
      return text.replace(urlRegex, 'p-sms.ru/testme');
    } else {
      return text;
    }
  };

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <div className={`sms_presenter sms_presenter-${locale()}`}>
        <div className="sms_presenter">
          <div id="sms_presenter_avatar" className="sms_presenter-avatar">
            {sender_name.charAt(0)}
          </div>
          <div id="sms_presenter_header" className="sms_presenter-header">
            {sender_name}
          </div>
          <div id="sms_presenter_message" className="sms_presenter-message">
            {attachmentUrl && <img className="sms_presenter-message-image" src={attachmentUrl} />}
            <div dangerouslySetInnerHTML={{ __html: shortLinksInText(text, shortUrl) }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextPreview;
