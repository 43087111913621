import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Steps, Col, Row, Divider, Button, notification, Spin, Space } from 'antd';
import { useClientsState } from './State.js';
import formSteps from './formSteps.js';
import translator from '@webpack/translator';
const t = translator('front_office.views.yclients_customer_clients.form.');

const Form = () => {
  const { state, updateState } = useClientsState();
  const navigate = useNavigate();
  const steps = formSteps['create_dispatch'];
  const [currentStep, setCurrentStep] = useState(0);
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isCurrentStepValid, setIsCurrentStepValid] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(false);

  const formMethods = useForm();

  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  const formValues = watch();
  console.log(formValues);
  const goToPrevStep = () => setCurrentStep((step) => step - 1);
  const goToNextStep = () => setCurrentStep((step) => step + 1);

  useEffect(() => {
    if (currentStep === 0) {
      setIsFirstStep(true);
      setIsLastStep(false);
    } else if (currentStep === steps.length - 1) {
      setIsFirstStep(false);
      setIsLastStep(true);
    } else {
      setIsFirstStep(false);
      setIsLastStep(false);
    }
  }, [currentStep]);

  useEffect(() => {
    if (formValues['phone_list'] == '') {
      navigate('/');
    }

    let validationRule = steps[currentStep]['validation'] || ((_) => true);
    let isValid = validationRule(formValues, state);
    setIsCurrentStepValid(isValid);
  }, [formValues, state, currentStep]);

  const handlePrevButton = () => {
    if (isFirstStep) {
      navigate('/');
    } else {
      goToPrevStep();
    }
  };

  const handleNextButton = () => {
    if (!isLastStep) {
      goToNextStep();
    }
  };

  if (location.pathname + location.search + location.hash === '/' && window.location.hash === '') {
    return <Navigate to="" replace={true} />;
  }

  const onSubmit = (_, e) => {
    e.preventDefault();
    setIsLoading(true);

    const preparedFormData = formValues;

    return axios({
      method: 'POST',
      data: preparedFormData,
      url: state.bulkCreatePath,
      headers: {
        'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content,
      },
    })
      .then((response) => {
        updateState({ response: response.data });
      })
      .catch((_error) => {
        updateState({ response: false });
      })
      .finally(() => {
        setIsLoading(false);
        navigate('/finish');

        setValue('external_id', Date.now());
      });
  };

  register('external_id', { value: Date.now() });

  return (
    <>
      <Breadcrumb className="ant-custom-breadcrumbs">
        <Breadcrumb.Item>{t('breadcrumb.clients')}</Breadcrumb.Item>
        <Breadcrumb.Item>{t('breadcrumb.send_form')}</Breadcrumb.Item>
      </Breadcrumb>
      <Steps
        responsive={false}
        className="ant-custom-steps"
        type="navigation"
        items={steps.map(({ title }) => ({ title }))}
        current={currentStep}
      />
      <FormProvider {...formMethods}>
        <Spin spinning={isLoading}>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit.bind(this))}>
            <input
              type="hidden"
              {...register('phone_list', { value: state.selectedPhoneNumbers.join(' ') })}
            />
            <input type="hidden" {...register('url_shorting', { value: state.url_shorting })} />

            <div className="yclients_customer_clients yclients_customer_clients-steps_body">
              {steps.map(({ Component }, index) => (
                <Component key={index} isVisible={currentStep === index} />
              ))}
            </div>

            <Divider />

            <Row>
              <Col sm={24} xl={3} />
              <Col sm={24} xl={18}>
                <Row justify={'space-between'}>
                  <Col>
                    <Button
                      className="ant-custom-btn"
                      htmlType="button"
                      onClick={handlePrevButton}
                      disabled={isSubmitting}
                    >
                      {isFirstStep ? t('back_button') : t('back_button')}
                    </Button>
                  </Col>
                  <Col>
                    <Space direction="vertical" size={12}>
                      <Button
                        className="ant-custom-btn"
                        htmlType={isLastStep ? 'submit' : 'button'}
                        type="primary"
                        onClick={handleNextButton}
                        disabled={!isCurrentStepValid || isSubmitting}
                      >
                        {isLastStep
                          ? (formValues['scheduled_at'] && t('schedule_save_and_close_button')) ||
                            t('save_and_close_button')
                          : t('next_button')}
                      </Button>

                      {isLastStep && formValues['scheduled_at'] && (
                        <span>{formValues['scheduled_at']} UTC</span>
                      )}
                    </Space>
                  </Col>
                </Row>
              </Col>

              <Col sm={24} xl={3} />
            </Row>
          </form>
        </Spin>
      </FormProvider>
    </>
  );
};

export default Form;
