import React from 'react';
import createServicesStep from './ServicesStep';
import createSaleServicesStep from './SaleServicesStep';
import createTextStep from './TextStep';
import createDispatchRoutingStep from './DispatchRoutingStep';
import createVisitTimeOffsetStep from './VisitTimeOffsetStep';
import createVisitFixedTimeOffsetStep from './VisitFixedTimeOffsetStep';
import createFeedbackStep from './FeedbackStep';
import translator from '@webpack/translator';
const t = translator('front_office.views.altegio_notification_settings.');

const formSteps =
{
  visit_confirmation: [
    createServicesStep({
      helpText: t('fields.services_help_visit_confirmation'),
    }),
    createVisitFixedTimeOffsetStep({
      fixedOffsetsStateKey: 'fixed_time_offsets',
      offsetsStateKey: 'before_visit_time_offsets',
      helpText: t('fields.time_help_reminder'),
      fixedTimeText: t('fields.fixed_time_reminder'),
      fixedTimeOnText: t('fields.fixed_time_on_reminder'),
      fixedTimeOffText: t('fields.fixed_time_off_reminder'),
      withoutDuplicatesText: t('fields.fixed_time_without_duplicates_reminder'),
    }),
    createTextStep({
      disabled: true,
      alerts: [
        <span>
          {t(`alerts.visit_confirmation_via_admin_start`)}
          <a style={{ textDecoration: 'underline' }} target='_blank' href='/whatsappproblems'>
            {t(`alerts.visit_confirmation_via_admin_link_text`)}
          </a>
          {t(`alerts.visit_confirmation_via_admin_end`)}
        </span>,
        <span>
          {t(`alerts.visit_confirmation_action_start`)}
          <a style={{ textDecoration: 'underline' }} target='_blank' href='/front_office/altegio_customers'>
            {t(`alerts.visit_confirmation_action_link_text`)}
          </a>
          {t(`alerts.visit_confirmation_action_end`)}
        </span>
      ],
      helpText: t('fields.text_help_booking_action'),
    }),
    createFeedbackStep({ type: 'confirm_visit' }),
    createFeedbackStep({ type: 'cancel_visit' }),
    createDispatchRoutingStep({ isForBot: true })
  ],
  booking_action: [
    createServicesStep({
      helpText: t('fields.services_help_booking_action'),
    }),
    createTextStep({
      alerts: [
        <span>
          {t(`alerts.duplicate_booking_action_start`)}
          <a style={{textDecoration: 'underline'}} target='_blank' href='https://alteg.io'>
            {t(`alerts.duplicate_booking_action_link_text`)}
          </a>
          {t(`alerts.duplicate_booking_action_end`)}
        </span>
      ],
      helpText: t('fields.text_help_booking_action'),
    }),
    createDispatchRoutingStep(),
  ],
  reminder: [
    createServicesStep({
      helpText: t('fields.services_help_reminder'),
    }),
    createVisitFixedTimeOffsetStep({
      fixedOffsetsStateKey: 'fixed_time_offsets',
      offsetsStateKey: 'before_visit_time_offsets',
      helpText: t('fields.time_help_reminder'),
      fixedTimeText: t('fields.fixed_time_reminder'),
      fixedTimeOnText: t('fields.fixed_time_on_reminder'),
      fixedTimeOffText: t('fields.fixed_time_off_reminder'),
      withoutDuplicatesText: t('fields.fixed_time_without_duplicates_reminder'),
    }),
    createTextStep(),
    createDispatchRoutingStep(),
  ],
  feedback_request: [
    createServicesStep({
      helpText: t('fields.services_help_feedback_request'),
    }),
    createVisitTimeOffsetStep({
      showSelectedRequest: true,
      offsetsStateKey: 'after_visit_time_offsets',
      helpText: t('fields.time_help_feedback_request'),
      alerts: [
        <span>
          {t(`alerts.duplicate_feedback_start`)}
          <a style={{textDecoration: 'underline'}} target='_blank' href='https://alteg.io'>
            {t(`alerts.duplicate_feedback_link_text`)}
          </a>
          {t(`alerts.duplicate_feedback_end`)}
        </span>
      ],
    }),
    createTextStep(),
    createDispatchRoutingStep(),
  ],
  bot_feedback_request: [
    createServicesStep({
      helpText: t('fields.services_help_bot_feedback_request'),
    }),
    createVisitTimeOffsetStep({
      showSelectedRequest: true,
      offsetsStateKey: 'after_visit_time_offsets',
      helpText: t('fields.time_help_feedback_request'),
      alerts: [
        <span>
          {t(`alerts.duplicate_feedback_start`)}
          <a style={{textDecoration: 'underline'}} target='_blank' href='https://alteg.io'>
            {t(`alerts.duplicate_feedback_link_text`)}
          </a>
          {t(`alerts.duplicate_feedback_end`)}
        </span>
      ],
    }),
    createTextStep({disabled: true}),
    createFeedbackStep({type: 'positive'}),
    createFeedbackStep({type: 'negative'}),
    createDispatchRoutingStep({isForBot: true}),
  ],
  return_visit: [
    createServicesStep({
      helpText: t('fields.services_help_return_visit'),
    }),
    createVisitFixedTimeOffsetStep({
      offsetsStateKey: 'return_visit_time_offsets',
      fixedOffsetsStateKey: 'return_visit_time_offsets',
      helpText: t('fields.time_help_return_visit'),
      fixedTimeText: t('fields.fixed_time_return_visit'),
      fixedTimeOnText: t('fields.fixed_time_on_return_visit'),
      fixedTimeOffText: t('fields.fixed_time_off_return_visit'),
      withoutDuplicatesText: t('fields.fixed_time_without_duplicates_return_visit'),
    }),
    createTextStep(),
    createDispatchRoutingStep(),
  ],
  pre_visit: [
    createServicesStep({
      helpText: t('fields.services_help_pre_visit'),
    }),
    createVisitTimeOffsetStep({
      offsetsStateKey: 'pre_visit_time_offsets',
      helpText: t('fields.time_help_pre_visit'),
    }),
    createTextStep(),
    createDispatchRoutingStep(),
  ],
  cross_sell: [
    createServicesStep({
      helpText: t('fields.services_help_cross_sell'),
    }),
    createSaleServicesStep(),
    createVisitTimeOffsetStep({
      offsetsStateKey: 'cross_sell_time_offsets',
      helpText: t('fields.time_help_cross_sell'),
    }),
    createTextStep(),
    createDispatchRoutingStep(),
  ],
};

export default formSteps;
